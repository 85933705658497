export const menuCheckBox = [
	{
		title: "Chọn môn học",
		link: false,
		submenu: [{ title: "Toán" }, { title: "Lý" }, { title: "Hóa" }, { title: "Sinh" }],
	},
	{
		title: "Chọn khu vực",
		link: false,
		submenu: [
			{ title: "Hà Đông" },
			{ title: "Cầu Giấy" },
			{ title: "Tây Hồ" },
			{ title: "Hoàng Mai" },
		],
	},
   
];
